<template>
  <div class="puncture">
    <el-radio-group v-model="ruleForm.is_fistula">
      <el-radio :label="1" v-if="!(ruleForm.is_fistula === 2 && isEdit)">
        非内瘘
      </el-radio>
      <el-radio :label="2" v-if="!(ruleForm.is_fistula === 1 && isEdit)">
        内瘘
      </el-radio>
    </el-radio-group>
    <div class="puncture-box" v-if="ruleForm.is_fistula === 2">
      <template v-if="!isEdit">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          label-width="240px"
          label-position="right"
          :inline="true"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="A端穿刺方法：">
            <el-select
              v-model="ruleForm.dict_a_chuanci_way"
              placeholder="请选择A端穿刺方法"
            >
              <el-option
                v-for="item in dictList[134000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="V端穿刺方法：">
            <el-select
              v-model="ruleForm.dict_v_chuanci_way"
              placeholder="请选择V端穿刺方法"
            >
              <el-option
                v-for="item in dictList[134000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="A端穿刺针类型：">
            <el-select
              v-model="ruleForm.dict_a_chuanci_zhen"
              placeholder="请选择A端穿刺针类型"
            >
              <el-option
                v-for="item in dictList[135000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="V端穿刺针类型：">
            <el-select
              v-model="ruleForm.dict_v_chuanci_zhen"
              placeholder="请选择V端穿刺针类型"
            >
              <el-option
                v-for="item in dictList[135000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="A端穿刺针型号：">
            <el-select
              v-model="ruleForm.dict_a_chuanci_zhen_type"
              placeholder="请选择A端穿刺针型号"
            >
              <el-option
                v-for="item in dictList[136000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="V端穿刺针型号：">
            <el-select
              v-model="ruleForm.dict_v_chuanci_zhen_type"
              placeholder="请选择V端穿刺针型号"
            >
              <el-option
                v-for="item in dictList[136000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <!--
          <el-form-item label="A端穿刺点距吻合口距离：">
            <el-input
              v-model="ruleForm.a_distance"
              placeholder="请输入A端穿刺点距吻合口距离"
            >
              <template #append>CM</template>
            </el-input>
          </el-form-item>
          -->
          <!--
          <el-form-item label="V端穿刺点距吻合口距离：">
            <el-input
              v-model="ruleForm.v_distance"
              placeholder="请输入V端穿刺点距吻合口距离"
            >
              <template #append>CM</template>
            </el-input>
          </el-form-item>
          -->
          <!--
          <el-form-item label="AtV端两穿刺点间距离：">
            <el-input
              v-model="ruleForm.atv_distance"
              placeholder="请输入AtV端两穿刺点间距离"
            >
              <template #append>CM</template>
            </el-input>
          </el-form-item>
          -->
        </el-form>
      </template>
      <div class="user-info flex-start" v-else>
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">A端穿刺方法：</div>
            <div class="cont">{{ ruleForm.a_chuanci_way }}</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">V端穿刺方法：</div>
            <div class="cont">{{ ruleForm.v_chuanci_way }}</div>
          </div>
          <!--
          <div class="info-item flex-between">
            <div class="lable">A端穿刺点距吻合口距离（cm）：</div>
            <div class="cont">{{ ruleForm.a_distance }}</div>
          </div>
          -->
        </div>
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">A端穿刺针类型：</div>
            <div class="cont">{{ ruleForm.a_chuanci_zhen }}</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">V端穿刺针类型：</div>
            <div class="cont">{{ ruleForm.v_chuanci_zhen }}</div>
          </div>
          <!--
          <div class="info-item flex-between">
            <div class="lable">V端穿刺点距吻合口距离（cm）：</div>
            <div class="cont">{{ ruleForm.v_distance }}</div>
          </div>
          -->
        </div>
        <div class="info-item-box norig">
          <div class="info-item flex-between">
            <div class="lable">A端穿刺针型号：</div>
            <div class="cont">{{ ruleForm.a_chuanci_zhen_type }}</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">V端穿刺针型号：</div>
            <div class="cont">{{ ruleForm.v_chuanci_zhen_type }}</div>
          </div>
          <!--
          <div class="info-item flex-between">
            <div class="lable">AtV端两穿刺点间距离（cm）：</div>
            <div class="cont">{{ ruleForm.atv_distance }}</div>
          </div>
          -->
        </div>
      </div>
    </div>
    <div class="puncture-box" v-if="ruleForm.is_fistula === 1">
      <template v-if="!isEdit">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="240px"
          label-position="right"
          :inline="true"
          class="demo-ruleForm col-333"
          :size="formSize"
        >
          <el-form-item label="穿刺方式：">
            <el-select
              v-model="ruleForm.dict_chuanci_type"
              placeholder="请选择穿刺方式"
            >
              <el-option
                v-for="item in dictList[134000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <div class="user-info flex-start" v-else>
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">穿刺方式</div>
            <div class="cont">{{ ruleForm.chuanci_type }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button class="color-vice" type="primary" @click="modPuncture">
        {{ isEdit ? '修改' : '取消' }}
      </el-button>
      <el-button
        v-if="!isEdit"
        class="color-main"
        type="primary"
        :loading="saveLoading"
        @click="savePuncture"
      >
        <i class="fa fa-briefcase"></i>保存
      </el-button>
      <el-button
        v-else
        class="color-red"
        :loading="delLoading"
        type="primary"
        @click="delPuncture"
      >
        <i class="fa fa-briefcase"></i>删除
      </el-button>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import service from '@/utils/request'
import { useRoute } from 'vue-router'
import { gitDictList } from '@/utils/tool.js'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  name: 'puncture',
  setup() {
    const state = reactive({
      ruleForm: {
        is_fistula: 2,
      },
      dictList: {},
      isEdit: true,
      saveLoading: false,
      delLoading: false,
    })

    onMounted(() => {
      getDataList()
      getDict()
    })
    const route = useRoute()
    const getDataList = async () => {
      let res = await service.post('/api/patient_chuanci/get_patient_chuanci', {
        patient_id: route.params.id,
      })
      if (res.code === 0) {
        if (res.data.id) {
          state.ruleForm = res.data
        }
      }
    }
    const getDict = async () => {
      state.dictList = await gitDictList([
        '134000000',
        '135000000',
        '136000000',
      ])
    }

    const modPuncture = () => {
      state.isEdit = !state.isEdit
    }
    const savePuncture = async () => {
      state.ruleForm.patient_id = route.params.id
      state.saveLoading = true
      let res = await service.post(
        '/api/patient_chuanci/save_patient_chuanci',
        state.ruleForm
      )
      state.saveLoading = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
        state.isEdit = true
      }
    }

    const delPuncture = async () => {
      ElMessageBox.confirm('您确定要删除吗?', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          if (!state.ruleForm.id) return
          state.delLoading = true
          let res = await service.post(
            '/api/patient_chuanci/delete_patient_chuanci',
            {
              id: state.ruleForm.id,
            }
          )
          state.delLoading = false
          if (res.code === 0) {
            ElMessage.success(res.msg)
            let type = state.ruleForm.is_fistula
            state.ruleForm = {
              is_fistula: type,
            }
          }
        })
        .catch(() => {})
    }

    return {
      ...toRefs(state),
      modPuncture,
      savePuncture,
      delPuncture,
      getDataList,
    }
  },
}
</script>

<style scoped lang="scss">
.puncture {
  .demo-ruleForms .el-form-item {
    width: 100%;
    margin-right: 0;
  }
  .el-form-item {
    width: 45%;
    margin-right: 10%;
    &:deep(.el-form-item__content) {
      width: calc(100% - 240px);
    }
  }
  .el-form-item:nth-child(even) {
    margin-right: 0;
  }
}
.user-info {
  flex-wrap: wrap;
}
.info-item-box {
  width: 400px;
  margin-right: 52px;
  // padding: 0 20px;
  padding: 0 16px;
  font-family: 'Source Han Sans CN-Regular';
  &.norig {
    margin-right: 0;
  }
  .info-item {
    font-size: 14px;
    color: #444444;
    margin-bottom: 24px;
  }
}
.el-radio-group {
  margin-bottom: 20px;
}
.footer {
  text-align: right;
  margin-top: 10px;
}
</style>
